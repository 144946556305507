setTimeout(() => {

	// add "loaded" class when all loaded
	setTimeout(() => {
		document.body.classList.add('makeshare-loaded');
	}, 500);

	// Header
	// move actions button and CART before Shop Local, only on desktop
	if (window.innerWidth >= 992) {
		let headerActions = document.querySelector('.header-actions-action--cta');
		let cartButton = document.querySelector('.header-actions .showOnDesktop');
		let shopLocalLink = document.querySelector('.header-nav-item > a[href="/shop-local"]').parentNode;
		if (headerActions != null) {
			let parentDiv = shopLocalLink.parentNode;

			if (cartButton != null) {
				parentDiv.insertBefore(cartButton, shopLocalLink);
			}

			if (headerActions != null) {
				parentDiv.append(headerActions);
			}
		}
	}

	// Add class to Fund-a-Maker pages
	///////////////////////////////////////////////////////////////////////////////
	let fundMakerPage = document.getElementById('fund-maker-page');
	if (fundMakerPage) {
		document.body.classList.add('fund-maker-page');
	}

	// Add class to Maker Profile pages
	///////////////////////////////////////////////////////////////////////////////
	let makerPage = document.getElementById('maker-page');
	if (makerPage) {
		document.body.classList.add('maker-page');
	}

  // Memberships page
  ///////////////////////////////////////////////////////////////////////////////
  var membershipPage = document.getElementById('membership-page');

  if (membershipPage) {
    document.body.classList.add('membership-page'); 

    // hide footer-logos on this page
    let footerLogos = document.querySelector('.footer-logos');
    if (footerLogos) {
    	footerLogos.parentNode.parentNode.classList.add('d-none');
    }
  }

  // Fade In when visible on scroll
  let membershipPartners = document.querySelectorAll('.membership-partners');
	if (membershipPartners) {
		membershipPartners.forEach((membershipPartner) => {
		  var observer = new IntersectionObserver((entries) => {
        membershipPartnerObserverCallback(entries, observer, membershipPartner);
			}, 
			{ threshold: 0 });
			observer.observe(membershipPartner);
		});
	}
	let delay = 0;
	const membershipPartnerObserverCallback = (entries, observer, partner) => {
		entries.forEach((entry, partner) => {
			if (entry.isIntersecting) {
				let partners = entry.target.querySelectorAll('.membership-partner');
				if (partners) {
					partners.forEach((partner) => {
						delay = delay + 200;
						setTimeout(() => {
							partner.querySelector('a').classList.add('visible');
						}, delay);
					});
				}
			}
		});
	};

	// Fund-a-Maker profiles move donation buttons
	/////////////////////////////////////////////////////////////////////////////
	// find any donation buttons
	if (document.body.classList.contains('fund-maker-page')) {
		let donationButtonBlocks = document.querySelectorAll('.sqs-block-donation');
		let previousSiblingBlock;
		let moveDonationHere;

		if (donationButtonBlocks) {
			donationButtonBlocks.forEach((donationButtonBlock) => {
				// check if it is preceded by a custom code block
				previousSiblingBlock = donationButtonBlock.previousSibling;
				if (previousSiblingBlock.classList.contains('sqs-block-code')) {

					// check if code block contains a move-donation-button-here div
					moveDonationHere = previousSiblingBlock.querySelector('.move-donation-here');
					if (moveDonationHere) {
						moveDonationHere.appendChild(donationButtonBlock);
					}
				}
			});
		}
	}

	// Fund-a-Maker profiles add button to first image
	////////////////////////////////////////////////////////////////////////////
	if (document.body.classList.contains('fund-maker-page')) {
		let fundMakerFirstImage = document.querySelector('.fund-maker-page .content > .sqs-layout > .row:first-child > .col:first-child .sqs-block-image');
		if (fundMakerFirstImage) {

			// get exiting sticky fund-a-maker button
			let stickyMaker = document.querySelector('.sticky-maker');

			if (stickyMaker != null) {
				let imageMakerButton = stickyMaker.cloneNode(true);

				imageMakerButton.classList.remove('sticky-maker');
				imageMakerButton.classList.add('image-maker-button');
				imageMakerButton.removeAttribute('href');
				imageMakerButton.setAttribute('tabindex', -1);
				fundMakerFirstImage.append(imageMakerButton);
			}
		}
	}

	// Height Friends
	////////////////////////////////////////////////////////////////////////////
	function heightFriends() {

		// match height with friend
		let heightFriends = document.querySelectorAll('[data-height-friend]');

		if (heightFriends) {

			if (window.innerWidth > 767) {
				let friendGroupHeights = {};

				let friendGroup;

				heightFriends.forEach((heightFriendElement) => {
					heightFriendElement.style.height = null;

					// make object of all friendGroups with largest height among them
				  friendGroup = heightFriendElement.dataset.heightFriend;
				  console.log(friendGroup);
				  if (!friendGroupHeights[friendGroup]) {
				  	friendGroupHeights[friendGroup] = { 
				  		name: friendGroup, 
				  		height: getComputedStyle(heightFriendElement).height
				  	};

				  } else {
			    	if (parseInt(getComputedStyle(heightFriendElement).height.replace('px', '')) > parseInt(friendGroupHeights[friendGroup].height.replace('px', ''))) {
			        friendGroupHeights[friendGroup] = { 
			        	name: friendGroup, 
			        	height: getComputedStyle(heightFriendElement).height
			        };
			      }
			    }
				});

				if (friendGroupHeights) {
					Object.keys(friendGroupHeights).forEach((friendGroupHeight) => {

						document.querySelectorAll('[data-height-friend=' + friendGroupHeights[friendGroupHeight].name + ']').forEach((friendElement) => {
							friendElement.style.height = friendGroupHeights[friendGroupHeight].height;
						});
					});
				}

			} else {

				heightFriends.forEach((heightFriendElement) => {
					heightFriendElement.style.height = null;
				});
			}
		}
	}
	heightFriends();

	// Hide code blocks used for page body classes
	let hideBlocks = document.querySelectorAll('.hide-block');
	if (hideBlocks) {
		hideBlocks.forEach((hideBlock) => {
			hideBlock.parentNode.parentNode.classList.add('d-none');
		});
	}

	// Home Shapes
	function animateHomeShapes(shapes, delayValue) {
		
	  document.getElementById('hover').addEventListener('click', () => {
	  	triangle.classList.add('finished');
	  	circle.classList.add('finished');
	  	square.classList.add('finished');

	    let trianglePepites = document.querySelectorAll('.triangle-pepite');
	    alternatePepites(trianglePepites);

	    let squarePepites = document.querySelectorAll('.square-pepite');
	    alternatePepites(squarePepites);
	  });
	  
	  document.getElementById('start').addEventListener('click', () => {
	    triangle.classList.remove('finished');
	    circle.classList.remove('finished');
	    square.classList.remove('finished');

	    triangle.classList.add('active');
	    setTimeout(() => {
	      triangle.classList.remove('active');
	      triangle.classList.add('finished');
	    	
	    	circle.classList.add('active');

		    setTimeout(() => {
		      circle.classList.remove('active');
		      circle.classList.add('finished');

		    	square.classList.add('active');

			    setTimeout(() => {
			      square.classList.remove('active');
			      square.classList.add('finished');

			      
			    }, delayValue);
	      
		    }, delayValue);

	    }, delayValue);
	  });
	}

  function hoverAnimation(animateElement, delayValue) {
    let animateElementContainer = animateElement.parentNode.parentNode.parentNode.parentNode;

    animateElement.addEventListener('mouseover', () => {
    	initiateHoverAnimation(animateElement, delayValue);
    });

    animateElementContainer.querySelectorAll('a').forEach((animateElementLink) => {
	    animateElementLink.addEventListener('mouseover', () => {
    		initiateHoverAnimation(animateElement, delayValue);
    	});
    });
  }

  function initiateHoverAnimation(animateElement, delayValue) {
    if (animateElement.classList.contains('animating') == false) {
      animateElement.classList.add('animating');
      setTimeout(() => {
        animateElement.classList.remove('animating');
      }, delayValue);
    }
  }


  let homeShapes = document.querySelectorAll('.makeshare-shape');
  if (homeShapes) {
  	homeShapes.forEach((homeShape) => {
	  	hoverAnimation(homeShape, 2000);
	  });
	}

	// Responsive Functions
	window.addEventListener('resize', () => {
		heightFriends();
	});
}, 500);

// JOIN STICKY BUTTON
//////////////////////////////////////////////////////
const stickyJoin = document.querySelector('.sticky-join');
if (stickyJoin != null) {
	// z-index no longer working when button inside .content-wrapper
	document.getElementById('page').prepend(stickyJoin);
}

// Logo
////////////////////////////////////////////////////////////////
// replace logo with animated SVG on first page load and always on home page

const headerLogos = document.querySelectorAll('.header-title-logo'); /* mobile and desktop */
const headerLogoSVG = document.getElementById('header-logo-svg');
const cookies = document.cookie.split('; ');
let logoCookie;

// only show logo the first time site is viewed or if home page
if (cookies.find(row => row.startsWith('logoCookie=')) == undefined) {
  document.cookie = "logoCookie=show";
	logoCookie = 'show';
} else {
	logoCookie = cookies.find(row => row.startsWith('logoCookie=')).split('=')[1];
}

if (logoCookie == undefined || logoCookie == null || logoCookie == '') {
	document.cookie = "logoCookie=show";
}

if (headerLogos && headerLogoSVG && (logoCookie == 'show' || document.body.classList.contains('homepage'))) {

	headerLogos.forEach((headerLogo) => {

		var headerLogoSVGClone = headerLogoSVG.cloneNode(true);

		headerLogo.querySelector('a').remove();
		headerLogo.append(headerLogoSVGClone);
		headerLogoSVGClone.classList.remove('d-none');
		headerLogo.style.display = 'block';
		headerLogo.style.visibility = 'visible';

	  const logoPaths = headerLogoSVGClone.querySelectorAll('svg path');
	  let delay = 0;

	  // shuffle the paths so they load randomly
	  let n;
	  let numbers = [0,1,2,3,4,5,6,7,8];
	  numbers = randomizeArray(numbers);

	  let randomPaths = [];
	  numbers.forEach((pathNumber) => {
	    randomPaths.push(logoPaths.item(pathNumber));
	  });

	  randomPaths.forEach((randomPath) => {
	    randomPath.classList.remove('animate');

	    setTimeout(function() {
	      randomPath.classList.add('animate');
	    }, delay);
	    delay = parseInt(delay) + 200;
	  });
	});

  // change cookie value to save that logo has been viewed
  document.cookie = "logoCookie=hide";

} else {
	headerLogos.forEach((headerLogo) => {
		headerLogo.style.display = 'block';
		headerLogo.style.visibility = 'visible';
	});
}

function randomizeArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
	  var j = Math.floor(Math.random() * (i + 1));
	  var temp = array[i];
	  array[i] = array[j];
	  array[j] = temp;
	}

	return array;
}